import React from "react"
import { Link } from "gatsby"
import { Main, Wrapper, Paper } from "../components/style"
import { Hero } from "../components/hero"

const NotFound = () => {
  const hero = {
    overlay: true,
  }

  return (
    <>
      <Hero hero={hero} />
      <Main>
        <Wrapper>
          <Paper>
            <h2>{`Page not found :(`}</h2>
            <p>It looks like you landed somewhere unknown.</p>
            <Link to="/">Take me home</Link>
          </Paper>
        </Wrapper>
      </Main>
    </>
  )
}

export default NotFound
